<template>
  <div>
    <v-dialog
      v-model="showForm"
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      fullscreen
      @keydown.esc="close"
    >
      <v-form ref="form" v-model="valid">
        <v-card flat>
          <v-card-title>
            {{ formTitle }}
            <v-spacer> </v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-if="
                    editedItem.id > 0 &&
                    ($store.state.auth.includes('01060') ||
                      $store.state.isadmin)
                  "
                  @click.stop="hist_click"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-clipboard-text-clock-outline </v-icon>
                </v-btn>
              </template>
              <span>Historique</span>
            </v-tooltip>
            <v-btn
              color="blue darken-1"
              text
              @click="validate"
              v-if="valider && editedItem.statut_id == '1' && editedItem.id > 0"
              :disabled="ch > 1"
            >
              Valider
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
              :disabled="ch == 1"
              v-if="modify"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click="close">
              {{ ch == 1 ? "Fermer" : "Annuler" }}
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            <h4>
              Code :
              {{
                editedItem.code_fournisseur
                  ? editedItem.code_fournisseur
                  : editedItem.code_client
              }}
              - Créé le {{ datefr(editedItem.date_crea) }} Par
              {{ editedItem.contact }}
            </h4>
          </v-card-subtitle>
          <v-progress-linear
            v-if="progress"
            indeterminate
            :size="30"
            :width="7"
            color="purple"
          ></v-progress-linear>
          <v-divider></v-divider>

          <v-card-text>
            <v-tabs
              vertical
              class="my-tabs"
              v-model="tab1"
              @change="tab_change"
            >
              <v-tab href="#1" key="1">
                <v-icon left> mdi-account-details </v-icon>Informations
                Generales
              </v-tab>
              <v-tab
                href="#2"
                key="2"
                v-if="
                  editedItem.id != -1 &&
                  editedItem.fournisseur &&
                  editedItem.statut_id > '1'
                "
              >
                <v-icon left> mdi-dolly </v-icon>
                Articles
              </v-tab>
              <v-tab
                href="#3"
                key="3"
                v-if="
                  editedItem.id != -1 &&
                  editedItem.client &&
                  spec &&
                  editedItem.statut_id > '1'
                "
              >
                <v-icon left> mdi-dolly</v-icon>
                Produits Specifiques
              </v-tab>
              <v-tab
                href="#4"
                key="4"
                v-if="editedItem.id != -1 && editedItem.statut_id > '1'"
              >
                <v-icon left> mdi-account-cash-outline </v-icon>
                Banques & Modes de payment
              </v-tab>
              <v-tab
                href="#5"
                key="5"
                v-if="
                  editedItem.id != -1 &&
                  editedItem.client &&
                  editedItem.statut_id > '1'
                "
              >
                <v-icon left> mdi-truck-check-outline </v-icon>
                Addresses de Livraison
              </v-tab>
              <v-tab
                href="#6"
                key="6"
                v-if="editedItem.id != -1 && editedItem.statut_id > '1'"
              >
                <v-icon left> mdi-account </v-icon>
                Contacts
              </v-tab>
              <v-tab
                href="#7"
                key="7"
                v-if="
                  editedItem.id != -1 &&
                  editedItem.statut_id > '1' &&
                  paye_bl &&
                  istier == 2 &&
                  editedItem.is_aux == '0'
                "
              >
                <v-icon left> mdi-account </v-icon>
                Auxiliaires
              </v-tab>
              <v-tab
                href="#8"
                key="8"
                v-if="editedItem.id != -1 && editedItem.statut_id > '1'"
              >
                <v-icon left> mdi-paperclip </v-icon>
                Documents attachés
              </v-tab>
              <v-tabs-items v-model="currentTab">
                <v-tab-item :value="'1'">
                  <v-row>
                    <!-- Empty Row -->
                    <v-col cols="12" sm="12" md="12"> </v-col>

                    <!-- Pays -->
                    <v-col cols="12" sm="2" md="2" v-if="istier != 0">
                      <v-autocomplete
                        outlined
                        :items="countrys"
                        item-text="label"
                        item-value="id"
                        dense
                        v-model="editedItem.country_id"
                        label="Pays"
                        :rules="[(v) => !!v || 'Pays obligatoire']"
                        @change="country_change"
                      ></v-autocomplete>
                    </v-col>
                    <!-- Forme Juridique -->

                    <v-col cols="12" sm="3" md="3" v-if="istier != 0">
                      <v-select
                        :items="formes"
                        :item-text="
                          (item) => (item.abrv ? item.abrv : item.libelle)
                        "
                        item-value="id"
                        outlined
                        dense
                        v-model="editedItem.form_id"
                        label="Forme Juridique"
                        :readonly="!modify"
                        :rules="[
                          (v) =>
                            !editedItem.country_id ||
                            formes.length == 0 ||
                            !!v ||
                            'Forme Juridique obligatoire',
                        ]"
                      >
                      </v-select>
                    </v-col>
                    <!-- Nom Tier -->
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.nom"
                        label="Nom"
                        :rules="[
                          (v) => !!v || 'Nom obligatoire',
                          (v) => lib || 'Nom existe !!',
                        ]"
                        :loading="libv"
                        :readonly="
                          !modify ||
                          editedItem.cmdCount != 0 ||
                          editedItem.factCount != 0 ||
                          editedItem.id > 0
                        "
                        @input="lib_change++"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Client -->
                    <v-col cols="12" sm="1" md="1" v-if="istier == 0">
                      <v-checkbox
                        v-model="editedItem.client"
                        label="Client"
                        @change="resetcheck"
                        :disabled="item.client == 1 && editedItem.cmdCount != 0"
                        readonly
                      ></v-checkbox>
                    </v-col>
                    <!-- Fournisseur -->
                    <v-col cols="12" sm="1" md="1" v-if="istier == 0">
                      <v-checkbox
                        v-model="editedItem.fournisseur"
                        label="Fournisseur"
                        @change="resetcheck"
                        :disabled="
                          item.fournisseur == 1 && editedItem.cmdCount != 0
                        "
                        readonly
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!-- Description -->
                    <v-col
                      cols="12"
                      sm="4"
                      :md="editedItem.country_id != '13' ? 7 : 9"
                    >
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.description"
                        label="Description"
                        :readonly="!modify"
                      ></v-text-field>
                    </v-col>
                    <!-- Devise -->
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                      v-if="
                        editedItem.country_id && editedItem.country_id != '13'
                      "
                    >
                      <v-autocomplete
                        outlined
                        :items="currencies"
                        item-text="name"
                        item-value="id"
                        dense
                        v-model="editedItem.currency_id"
                        label="Devise"
                        :rules="[(v) => !!v || 'Devise obligatoire']"
                        :readonly="!modify"
                      ></v-autocomplete>
                    </v-col>
                    <!-- Gere par -->
                    <v-col cols="12" sm="2" md="2">
                      <v-autocomplete
                        v-model="editedItem.user_id"
                        :items="users"
                        item-text="name"
                        item-value="id"
                        label="Géré Par"
                        outlined
                        dense
                        clearable
                        v-if="
                          $store.state.auth.includes('01058') ||
                          $store.state.isadmin
                        "
                        :readonly="!modify"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <!-- Statut -->
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                      v-if="valider && editedItem.id > 0"
                    >
                      <v-autocomplete
                        :items="status_list"
                        v-model="editedItem.statut_id"
                        item-text="name"
                        item-value="id"
                        label="Statut"
                        outlined
                        dense
                        :readonly="!modify"
                      ></v-autocomplete>
                    </v-col>
                    <!-- TYype -->
                    <v-col cols="12" sm="2" md="2">
                      <v-autocomplete
                        v-model="editedItem.tier_type_id"
                        :items="tiertypes"
                        item-text="tier_type"
                        item-value="id"
                        label="Type"
                        outlined
                        dense
                        :readonly="!modify"
                      ></v-autocomplete>
                    </v-col>
                    <!-- Code compta -->
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                      v-if="
                        editedItem.id > 0 &&
                        (istier != 0 || (editedItem.code_cmpt && istier == 0))
                      "
                    >
                      <v-autocomplete
                        :items="plancomptable"
                        :item-text="(item) => item.account_number"
                        item-value="id"
                        outlined
                        dense
                        v-model="editedItem.code_cmpt"
                        label="Code Comptable"
                        readonly
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                      v-if="
                        editedItem.id > 0 &&
                        !editedItem.code_cmpt &&
                        istier == 0
                      "
                    >
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="new_cc"
                        label="Code Comptable"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="1"
                      md="1"
                      v-if="
                        editedItem.id > 0 &&
                        !editedItem.code_cmpt &&
                        istier == 0
                      "
                    >
                      <v-btn
                        color="blue darken-1"
                        @click="cc_click"
                        :disabled="!new_cc"
                      >
                        <v-icon>mdi-content-save-all-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <!-- Categorie -->
                    <v-col cols="12" sm="2" md="5">
                      <selecttree
                        :items="cat_tier"
                        :nbr_chips="4"
                        v-model="editedItem.categories"
                        :value="editedItem.categories"
                        :label="'Categories'"
                        :readonly="!modify"
                        :dense="true"
                      >
                      </selecttree>
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="editedItem.country_id == 13 && istier != 0"
                  >
                    <!-- RC -->
                    <v-col cols="12" sm="2" md="3">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.RC"
                        label="Registre Commerce"
                        @keyup="uppercase"
                        :rules="[(v) => rc || 'Registre Commerce existe !!']"
                        :loading="libv"
                        @input="lib_change++"
                      ></v-text-field>
                    </v-col>
                    <!-- NIF -->
                    <v-col cols="12" sm="2" md="3">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.NIF"
                        label="NIF"
                        counter
                        :rules="[(v) => nif || 'NIF existe !!']"
                        :loading="libv"
                        @input="lib_change++"
                      ></v-text-field>
                    </v-col>
                    <!-- NIS -->
                    <v-col cols="12" sm="2" md="3">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.NIS"
                        label="NIS"
                        :rules="[(v) => nis || 'NIS existe !!']"
                        :loading="libv"
                        @input="lib_change++"
                      ></v-text-field>
                    </v-col>
                    <!-- No Art -->
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.ART"
                        label="N° Article"
                        counter
                        :rules="[(v) => art || 'N° Article existe !!']"
                        :loading="libv"
                        @input="lib_change++"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!-- Adresse -->
                    <v-col cols="12" sm="2" md="7">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.adresse"
                        label="Adresse"
                        :rules="[(v) => !!v || 'Adresse obligatoire']"
                        :readonly="!modify"
                      ></v-text-field>
                    </v-col>
                    <!-- Departement -->
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                      v-if="editedItem.country_id == 13"
                    >
                      <v-autocomplete
                        outlined
                        :items="departements"
                        item-text="nom_departement"
                        item-value="id"
                        dense
                        v-model="editedItem.departement_id"
                        label="Departement"
                        @change="editedItem.ville_id = ''"
                        :readonly="!modify"
                      ></v-autocomplete>
                    </v-col>
                    <!-- Ville -->
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                      v-if="editedItem.country_id == 13"
                    >
                      <v-select
                        :items="villes"
                        item-text="nom_ville"
                        item-value="id"
                        outlined
                        dense
                        v-model="editedItem.ville_id"
                        label="Ville"
                        :readonly="!modify"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <!-- email -->
                    <v-col cols="12" sm="2" md="3">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.email"
                        label="E-mail"
                        :rules="[
                          (v) =>
                            !v || /.+@.+\..+/.test(v) || 'E-mail non valide',
                        ]"
                        :readonly="!modify"
                      ></v-text-field>
                    </v-col>
                    <!-- Tel -->
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        outlined
                        v-model="editedItem.tel"
                        :readonly="!modify"
                        dense
                        label="Tel"
                      ></v-text-field>
                    </v-col>
                    <!-- Fax-->
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        outlined
                        dense
                        v-model="editedItem.fax"
                        label="Fax"
                        :readonly="!modify"
                      ></v-text-field>
                    </v-col>
                    <!-- Mobile -->
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        outlined
                        dense
                        v-model="editedItem.mobile"
                        label="Mobile"
                        :readonly="!modify"
                      ></v-text-field>
                    </v-col>
                    <!-- GPS -->
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.gps"
                        label="GPS"
                        :readonly="!modify"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row dense>
                    <!-- Exo Tva -->
                    <v-col
                      cols="12"
                      sm="2"
                      md="3"
                      v-if="editedItem.country_id == 13 && istier != 0"
                    >
                      <v-checkbox
                        v-model="editedItem.exe_tva"
                        label="Est exhoneré de TVA "
                        :readonly="!modify"
                      ></v-checkbox>
                    </v-col>
                    <!-- aff exo tva -->
                    <v-col cols="12" sm="6" md="6" v-if="editedItem.exe_tva">
                      <v-checkbox
                        v-model="editedItem.aff_exe"
                        label="Afficher la mention 'exhoneré TVA sur les factures'"
                        :readonly="!modify"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :value="'2'">
                  <listitems
                    :list="products_vente"
                    :qDelete="Qproductventedelete"
                    :Get_suf="'ProductArticle'"
                    title="Articles"
                    :headers="products_headers"
                    :master="true"
                    :add="modify"
                    :del="modify"
                    :del_disable="pvdetail.nbcmd > 0 || pvdetail.nbmvm > 0"
                    :showedit="false"
                    :key="aff + 10"
                    @rowselect="SelectPVForm"
                    @open="OpenPVForm"
                    @dd_click="article_statut_change"
                  >
                  </listitems>
                </v-tab-item>
                <v-tab-item :value="'3'">
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="2"
                        md="2"
                        v-if="$store.state.isadmin"
                      >
                        <v-text-field
                          autocomplete="off"
                          class="inputPrice"
                          type="number"
                          outlined
                          dense
                          v-model.number="taux_prix"
                          label="Taux (%)"
                          :readonly="!modify"
                          hide-spin-buttons
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="2"
                        md="2"
                        v-if="$store.state.isadmin"
                      >
                        <v-btn
                          color="blue darken-1"
                          @click="price_modif(1)"
                          :disabled="
                            !taux_prix ||
                            products_achat.filter((elm) => elm.selected)
                              .length == 0
                          "
                        >
                          Augmenter Prix
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="2"
                        md="2"
                        v-if="$store.state.isadmin"
                      >
                        <v-btn
                          color="blue darken-1"
                          @click="price_modif(-1)"
                          :disabled="
                            !taux_prix ||
                            products_achat.filter((elm) => elm.selected)
                              .length == 0
                          "
                        >
                          Diminuer Prix
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="2" md="12">
                        <listitems
                          :list="products_achat"
                          title="Produits"
                          :headers="products_spec"
                          :master="true"
                          :add="false"
                          :del="false"
                          @open="OpenProductForm"
                          :showedit="false"
                          :key="kps"
                          :multiple="true"
                          @dd_click="product_spec_statut_change"
                        >
                        </listitems>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item :value="'4'">
                  <v-row>
                    <v-col cols="12" sm="2" md="12">
                      <listitems
                        :list="tier_agences"
                        :qCreate="Qagencecreate"
                        :qUpdate="Qagenceupdate"
                        :qDelete="Qagencedelete"
                        :Get_suf="'TierAgence'"
                        title="Agences"
                        :headers="agences_headers"
                        :master="false"
                        :add="modify"
                        :del="modify"
                        :Update="modify"
                        :showstd="true"
                        :key="aff + 10"
                      >
                      </listitems>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="8">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4" md="4">
                      <v-select
                        dense
                        :items="modes_list"
                        item-text="mode"
                        item-value="id"
                        v-model="editedItem.modepaiement"
                        :readonly="!modify"
                        label="Mode de Paiement"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="2" md="4">
                      <v-select
                        dense
                        :items="modalites"
                        item-text="libelle"
                        item-value="id"
                        :readonly="!modify"
                        required
                        v-model="editedItem.modalite_id"
                        label="Modalité de Paiement"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        autocomplete="off"
                        class="inputPrice"
                        type="number"
                        dense
                        v-model.number="editedItem.credit_acc"
                        :readonly="!modify"
                        label="En cours Max"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3">
                      <v-text-field
                        autocomplete="off"
                        class="inputPrice"
                        type="number"
                        dense
                        v-model.number="editedItem.situation_ini"
                        readonly
                        label="Situation initiale"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3" v-if="paye_bl">
                      <v-text-field
                        autocomplete="off"
                        class="inputPrice"
                        type="number"
                        dense
                        v-model.number="editedItem.situation_bl_ini"
                        readonly
                        label="Situation BLs initiale"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :value="'5'">
                  <listitems
                    :title="'Adresses de livraison'"
                    :key="editedItem.id"
                    :headers="adresses_headers"
                    :list="tiersadresses"
                    :showedit="false"
                    :qDelete="Qdelete"
                    @open="OpenAdressForm"
                    :add="modify"
                    :del="modify"
                  ></listitems>
                </v-tab-item>
                <v-tab-item :value="'6'">
                  <listitems
                    :title="'Contacts'"
                    :key="editedItem.id"
                    :headers="contacts_headers"
                    :list="tierscontacts"
                    :showedit="true"
                    :showstd="true"
                    :qCreate="Qcreate_contact"
                    :qUpdate="Qupdate_contact"
                    :qDelete="Qdelete_contact"
                    :Get_suf="'Contact'"
                    :add="modify"
                    :del="modify"
                    :Update="modify"
                  ></listitems>
                </v-tab-item>
                <v-tab-item :value="'7'">
                  <listitems
                    :title="'Auxiliaire'"
                    :key="kaux"
                    :headers="auxs_headers"
                    :list="tiers_auxs"
                    :showedit="true"
                    :showstd="true"
                    :qCreate="Qcreate_aux"
                    :qUpdate="Qupdate_aux"
                    :qDelete="Qdelete_aux"
                    :Get_suf="'TierAux'"
                    @rowselect="SelectAux"
                    :del_disable="aux.nbfact > 0"
                    :add="modify"
                    :del="modify"
                    :Update="modify"
                    @add="refresh_aux"
                    @delete="refresh_aux"
                  ></listitems>
                </v-tab-item>
                <v-tab-item :value="'8'">
                  <filelist
                    :item="editedItem"
                    :isform="false"
                    :key="fdocl"
                    :editer="modify"
                    :doc_type="0"
                    :auth="auth"
                  >
                  </filelist>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>
          <timeline
            :document_type="0"
            :document_id="parseInt(editedItem.id)"
            v-model="drawer"
            :operations="operations"
            :doc_type="
              istier == 0 ? 'Tier' : istier == 2 ? 'Client' : 'Fournisseur'
            "
            :doc_code="
              istier == 2 ? editedItem.code_client : editedItem.code_fournisseur
            "
          >
          </timeline>
        </v-card>
      </v-form>
    </v-dialog>

    <adresses
      :item="adress"
      :key="adress.id"
      :tier_id="parseInt(editedItem.id)"
      :addresses="tiersadresses"
      :showForm="!isAdressClosed"
      @close="closeAdressForm"
      :modify="modify"
      :departements="departements"
    ></adresses>

    <articleform
      :item="pvdetail"
      :items="products_vente"
      :key="pv"
      :readonly="!modify"
      :showForm="!isPVClosed"
      :cat_list="cat_product"
      @close="closePVForm"
      @refresh="close"
      :parametre="5"
    >
    </articleform>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
Array.prototype.equals = function (array) {
  // if the other array is a falsy value, return
  if (!array) return false;

  // compare lengths - can save a lot of time
  if (this.length != array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] != array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};

import CREATE_TIER_AGENCE from "../graphql/Tier/CREATE_TIER_AGENCE.gql";
import UPDATE_TIER_AGENCE from "../graphql/Tier/UPDATE_TIER_AGENCE.gql";
import DELETE_TIER_AGENCE from "../graphql/Tier/DELETE_TIER_AGENCE.gql";
import INSERT_TIER from "../graphql/Tier/INSERT_TIER.gql";
import TIER_UPDATE from "../graphql/Tier/TIER_UPDATE.gql";
import UPDATE_TIER from "../graphql/Tier/UPDATE_TIER.gql";
import UPDATE_PRODUCT from "../graphql/Product/UPDATE_PRODUCT.gql";
import UPDATE_PRODUCT_MULTI from "../graphql/Product/UPDATE_PRODUCT_MULTI.gql";
import UPDATE_PRODUCT_ARTICLE from "../graphql/Product/UPDATE_PRODUCT_ARTICLE.gql";
import DELETE_ADRESS from "../graphql/Adress/DELETE_ADRESS.gql";
import DELETE_CONTACT from "../graphql/Contact/DELETE_CONTACT.gql";
import DELETE_AUX from "../graphql/Tier/DELETE_TIER_AUX.gql";
import DELETE_PRODUCT_CLIENT from "../graphql/Product/DELETE_PRODUCT_CLIENT.gql";
import DELETE_PRODUCT_ARTICLE from "../graphql/Product/DELETE_PRODUCT_ARTICLE.gql";
import CREATE_CONTACT from "../graphql/Contact/CREATE_CONTACT.gql";
import UPDATE_CONTACT from "../graphql/Contact/UPDATE_CONTACT.gql";
import CREATE_AUX from "../graphql/Tier/CREATE_TIER_AUX.gql";
import UPDATE_AUX from "../graphql/Tier/UPDATE_TIER_AUX.gql";

import DELETE_DOC from "../graphql/File/DELETE_DOC.gql";
import ALLTIERS from "../graphql/Tier/ALLTIERS.gql";
import UPDATE_CCT from "../graphql/Tier/UPDATE_CCT.gql";

import { spec } from "print/data.js";
import { myBL } from "print/data.js";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    adresses: () => import("../components/AdresseForm.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    articleform: () => import("../components/ArticleForm.vue"),
    selecttree: () => import("../components/SelectTree.vue"),
    filelist: () => import("../components/FileList.vue"),
    timeline: () => import("../components/Timeline.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    istier: { type: Number, default: 0 },
    status_list: Array,
    NewClientid: String,
    Newfournisseurid: String,
    plancomptable: Array,
    countrys: Array,
    cat_tier: Array,
    cat_product: Array,
    modify: Boolean,
    valider: Boolean,
    modes: Array,
    modalites: Array,
    users: Array,
    currencies: Array,
    route: Object,
  },
  data: () => ({
    tab1: 1,
    kaux: 1200,
    currentTab: 1,
    lib: true,
    libv: false,
    rc: true,
    art: true,
    nif: true,
    nis: true,
    drawer: null,
    mask_rc: [
      /\d/,
      /\d/,
      /[A-a,B-b]/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      "0",
      "0",
      "/",
      /[0-5]/,
      /[0-9]/,
    ],
    paye_bl: false,
    taux_prix: null,
    phone: {
      number: "",
      valid: false,
      country: undefined,
    },

    progress: false,
    aff: 0,
    pa: 100,
    pv: 200,
    kps: 300,
    fdocl: 1000,
    ch: 0,
    lib_change: 0,
    tabs: null,
    new_cc: null,
    closeOnContentClick: false,
    x: "",
    valid: true,
    datepicker1: false,

    tiersadresses: [],
    tierscontacts: [],
    tiers_auxs: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    agences_headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Agence",
        value: "agence",
        slot: "href",
        selected: true,
        edit: true,
        type: "text",
        rules: [(v) => !!v || "Agence obligatoire"],
        sm: 12,
        md: 12,
      },
      {
        text: "N° Compte",
        value: "nocompte",
        selected: true,
        hiden: true,
        edit: true,
        rules: [
          (v) =>
            !v || v.length == 20 || "Nombre de chiffre doit être égale à 20",
        ],
        type: "text",
        sm: 6,
        md: 6,
      },
      {
        text: "Swift",
        value: "swift",
        selected: true,
        hiden: true,
        edit: true,
        type: "text",
        sm: 6,
        md: 6,
      },
      {
        text: "IBAN",
        value: "iban",
        selected: true,
        hiden: true,
        edit: true,
        type: "text",
        sm: 6,
        md: 6,
      },
      {
        text: "Adresse",
        value: "adresse",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
      {
        text: "Pays",
        value: "fk_pays",
        selected: true,
        slot: "simple-select",
        edit: true,
        type: "select",
        rules: [(v) => !!v || "Pays obligatoire"],
        select_list: [],
        show_list: [],
        item_value: "id",
        item_text: "label",
        default: null,
        sm: 6,
        md: 6,
      },
      {
        text: "Default",
        value: "isdefault",
        selected: true,
        slot: "checkbox",
      },
      {
        text: "Default",
        value: "default_agence",
        selected: true,
        edit: true,
        hiden: true,
        slot: "checkbox",
        type: "check-box",
        sm: 6,
        md: 6,
      },
      {
        text: "tier_id",
        value: "tier_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
    ],
    products_headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Ref.",
        value: "ref",
        selected: true,
      },
      {
        text: "Produit",
        value: "product_name",
        slot: "href",
        selected: true,
      },
      {
        text: "Designation Externe",
        value: "label_ext",
        selected: true,
      },
      {
        text: "Ref.Externe",
        value: "ref_ext",
        selected: true,
      },
      {
        text: "Prix d'achat",
        value: "prixachat",
        selected: true,
      },
      {
        text: "Dernier Achat",
        value: "date_dern_achat",
        slot: "date",
        selected: false,
      },

      {
        text: "Unite",
        value: "unit",
        selected: true,
      },
      {
        text: "Delai",
        value: "delai",
        selected: false,
      },
      {
        text: "Qualite",
        value: "qualite",
        selected: false,
      },

      {
        text: "Dernier Mouvement",
        value: "date_dern_mvm",
        slot: "date",
        selected: false,
      },
      {
        text: "Statut",
        value: "statuts_name",
        slot: "dropdown",
        dd_items: [
          { id: "1", title: "Temporaire", color: "gray" },
          { id: "2", title: "Actif", color: "green" },
          { id: "3", title: "Archive", color: "orange" },
          { id: "4", title: "Obsolète", color: "red" },
        ],
        color: "color",
        column: "statut_id",
        valeur: "3",
        selected: true,
      },
    ],
    products_spec: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
        hoover_list2: "attributs",
        hoover_name: "name",
        hoover_value: "value",
      },
      {
        text: "Ref.",
        value: "ref",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        slot: "href",
        selected: true,
      },
      {
        text: "Prix Vente",
        value: "prixvente",
        selected: true,
      },
      {
        text: "Statut",
        value: "statuts_name",
        slot: "dropdown",
        dd_items: [
          { id: "1", title: "Temporaire", color: "gray" },
          { id: "2", title: "Actif", color: "green" },
          { id: "3", title: "Archive", color: "orange" },
          { id: "4", title: "Obsolète", color: "red" },
        ],
        color: "color",
        column: "statut_id",
        valeur: "3",
        selected: true,
      },
    ],
    defaultItem: {
      id: -1,
      modalite_id: 1,
      modepaiement: 1,
      statut_id: "1",
      tier_type_id: "10",
      client: true,
      fournisseur: false,
      date_crea: null,
      cmdCount: [],
      factCount: [],
      categories: [],
      docs: [],
      produits_vente: [],
      country_id: "13",
      currency_id: "114",
    },
    adresses_headers: [
      {
        text: "Libelle",
        value: "label",
        type: "text",
        slot: "href",
        selected: true,
      },
      {
        text: "Adresse",
        value: "adresse",
        type: "text",
        selected: true,
      },
      {
        text: "Wilaya",
        value: "departement_name",
        selected: true,
      },
      {
        text: "Ville",
        value: "ville_name",
        selected: true,
      },
    ],
    contacts_headers: [
      {
        text: "tier_id",
        value: "tier_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
      {
        text: "Nom",
        value: "nom",
        type: "text",
        sm: "6",
        md: "12",
        rules: [(v) => !!v || "Nom obligatoire"],
        slot: "href",
        edit: true,
        selected: true,
      },
      {
        text: "Fonction",
        value: "fonction",
        selected: true,
        type: "text",
        sm: "6",
        md: "8",
        edit: true,
      },
      {
        text: "Telephone",
        value: "tel",
        selected: true,
        type: "text",
        sm: "6",
        md: "4",
        edit: true,
      },
      {
        text: "Adresse",
        value: "adresse",
        selected: true,
        type: "text",
        sm: "6",
        md: "12",
        edit: true,
      },
      {
        text: "Email-Pro",
        value: "email_pro",
        rules: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail non valide"],
        selected: true,
        type: "text",
        sm: "6",
        md: "6",
        edit: true,
      },
      {
        text: "Email-Perso",
        value: "email_perso",
        rules: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail non valide"],
        selected: true,
        type: "text",
        sm: "6",
        md: "6",
        edit: true,
      },
      {
        text: "mobile 1",
        value: "mobile1",
        selected: true,
        type: "text",
        sm: "6",
        md: "6",
        edit: true,
      },
      {
        text: "mobile 2",
        value: "mobile2",
        selected: true,
        type: "text",
        sm: "6",
        md: "6",
        edit: true,
      },
    ],
    auxs_headers: [
      {
        text: "tier_id",
        value: "tier_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "aux_code",
        slot: "href",
        selected: true,
      },
      {
        text: "Auxiliaire",
        value: "aux_name",
        selected: true,
      },
      {
        text: "Auxiliaire",
        value: "aux_id",
        type: "cur_tier",
        sm: "10",
        md: "10",
        rules: [(v) => !!v || "Nom obligatoire"],
        label: "aux_name",
        code: "aux_code",
        variableadd: {
          TypeScope: 2,
          Kind: 0,
        },
        whereadd: [{ column: "ID", operator: "NEQ", value: -1 }],
        slot: "href",
        edit: true,
        hiden: true,
        selected: true,
      },
      {
        text: "Default",
        value: "aux_default",
        selected: true,
        edit: true,
        hiden: true,
        slot: "checkbox",
        type: "check-box",
        sm: 2,
        md: 2,
      },
      {
        text: "Default",
        value: "isdefault",
        selected: true,
        slot: "checkbox",
      },
    ],
    isAdressClosed: true,
    isPVClosed: true,
    adress: {},
    Qdelete: {},
    padetail: {},
    pvdetail: {},
    aux: {},
    cat_change: 0,
  }),

  computed: {
    auth() {
      let a = this.istier == 0 ? "01001" : this.istier == 1 ? "01029" : "01030";
      return a;
    },
    tier_agences: function () {
      return this.editedItem.id > 0 ? this.editedItem.agences : [];
    },

    tiertypes() {
      return this.istier == 0
        ? this.$store.state.tiers_types.filter((elm) => elm.id == 13)
        : this.istier == 2
        ? this.$store.state.tiers_types.filter((elm) => elm.type == "C")
        : this.$store.state.tiers_types.filter((elm) => elm.type == "F");
    },
    modes_list() {
      return this.modes.filter(
        (elm) => elm.type == (this.editedItem.country_id == 13 ? 0 : 1)
      );
    },
    spec() {
      return spec;
    },
    Qagencecreate() {
      return CREATE_TIER_AGENCE;
    },
    Qagenceupdate() {
      return UPDATE_TIER_AGENCE;
    },
    Qagencedelete() {
      return DELETE_TIER_AGENCE;
    },
    Qdelete_doc() {
      return DELETE_DOC;
    },
    Qcreate_contact() {
      return CREATE_CONTACT;
    },
    Qupdate_contact() {
      return UPDATE_CONTACT;
    },
    Qdelete_contact() {
      return DELETE_CONTACT;
    },
    Qcreate_aux() {
      return CREATE_AUX;
    },
    Qupdate_aux() {
      return UPDATE_AUX;
    },
    Qdelete_aux() {
      return DELETE_AUX;
    },
    products_vente: function () {
      return this.editedItem.id > 0 ? this.editedItem.produits_vente : [];
    },
    products_achat: function () {
      return this.editedItem.id > 0 ? this.editedItem.produits_achat : [];
    },
    operations: function () {
      return this.editedItem.id > 0 ? this.editedItem.operations : [];
    },
    Qproductachatdelete() {
      return DELETE_PRODUCT_CLIENT;
    },
    Qproductventedelete() {
      return DELETE_PRODUCT_ARTICLE;
    },

    formes() {
      let f = [];
      if (this.countrys) {
        let i = this.countrys.findIndex(
          (elm) => elm.id == this.editedItem.country_id
        );
        if (i >= 0) {
          f = this.countrys[i].formes;
        }
      }

      return f;
    },
    departements() {
      let d = [];
      if (this.countrys) {
        let i = this.countrys.findIndex(
          (elm) => elm.id == this.editedItem.country_id
        );
        if (i >= 0) d = this.countrys[i].departements;
      }
      return d;
    },
    villes() {
      let v = [];
      if (this.editedItem.departement_id && this.departements) {
        let i = this.departements.findIndex(
          (elm) => elm.id == this.editedItem.departement_id
        );
        if (i >= 0) v = this.departements[i].villes;
      }
      return v;
    },

    formTitle() {
      let tier =
        this.istier == 0
          ? "Tier "
          : this.istier == 2
          ? "Client "
          : "Fournisseur ";
      return this.editedItem.id < 0
        ? "Nouveau " + tier
        : tier + " : " + this.editedItem.nom;
    },

    Client_Rules() {
      return [
        (v) => !!v || !!this.editedItem.fournisseur || "Type tier obligatoire",
      ];
    },
    fournisseurrules() {
      return [
        (v) => !!v || !!this.editedItem.client || "Type tier obligatoire",
      ];
    },
  },
  watch: {
    editedItem: {
      handler() {
        this.ch++;
      },
      deep: true,
    },
    "editedItem.categories": {
      handler() {
        this.cat_change++;
      },
      deep: true,
    },
  },
  created() {
    if (
      myBL == "n" &&
      (this.$store.state.auth.includes("05021") || this.$store.state.isadmin)
    )
      this.paye_bl = true;
    this.defaultItem.date_crea = this.$store.state.today;
    if (this.istier == 2) this.defaultItem.code_client = this.NewClientid;
    if (this.istier == 1)
      this.defaultItem.code_fournisseur = this.Newfournisseurid;
    if (!this.valider) {
      this.products_headers[12].slot = "chip";
      this.products_spec[5].slot = "chip";
    }
  },
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.tiersadresses = this.item.adresses;
        this.tierscontacts = this.item.contacts;
        this.tiers_auxs = this.item.auxiliaires;
        this.kaux++;
        this.contacts_headers[0].default = this.item.id;
        this.auxs_headers[0].default = this.item.id;
        this.refresh_aux();
        this.products_headers[6].text =
          "Prix d'achat (" + this.item.devise + ")";
        this.agence_param();
      } else {
        this.defaultItem.client = this.istier == 2 ? true : false;
        this.defaultItem.fournisseur = this.istier == 1 ? true : false;
        this.editedItem = Object.assign({}, this.defaultItem);
        if (this.tiertypes.length == 1)
          this.editedItem.tier_type_id = this.tiertypes[0].id;
        else {
          if (
            this.istier == 2 &&
            this.tiertypes.findIndex((elm) => elm.id == "5") >= 0
          )
            this.editedItem.tier_type_id = "5";
          if (
            this.istier == 1 &&
            this.tiertypes.findIndex((elm) => elm.id == "10") >= 0
          )
            this.editedItem.tier_type_id = "10";
        }

        if (this.item.nom) {
          this.editedItem.nom = this.item.nom;
          this.editedItem.statut_id = "1";
        }
      }
    }
    this.Qdelete = DELETE_ADRESS;

    this.products_headers[6].hiden = !(
      this.$store.state.auth.includes("01027") || this.$store.state.isadmin
    );

    this.products_spec[4].hiden = !(
      this.$store.state.auth.includes("01027") || this.$store.state.isadmin
    );
  },

  methods: {
    async cc_click() {
      //verif if exist
      let i = this.plancomptable.findIndex(
        (elm) => elm.account_number == this.new_cc
      );
      if (i >= 0) {
        this.snackbar_text = "Code comptable exist déja verifier!!";
        this.snackbar_color = "error";
        this.snackbar = true;
      } else if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Vous-êtes sur le point de mettre a jour le compte comptable du tier " +
            this.editedItem.nom +
            " !! Etês-vous sûr?",
          { color: "orange darken-3" }
        )
      ) {
        let r = await this.maj(UPDATE_CCT, {
          id: this.editedItem.id,
          label: this.editedItem.nom,
          account_number: this.new_cc,
        });
        if (r) {
          this.editedItem.code_cmpt = r.UpdateCCT;
          this.snackbar_text = "Compte comptable maj avec succes";
          this.snackbar_color = "success";
          this.snackbar = true;
          this.$emit("refresh_pl");
          this.$store.dispatch("Changed", true);
          this.$nextTick(() => {
            this.ch = 1;
          });
        } else {
          this.snackbar_text = "Erreur";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
      }
    },
    async price_modif(e) {
      if (this.products_achat.filter((elm) => elm.selected).length > 0)
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Vous-êtes sur le point " +
              (e > 0 ? "d'augmenter" : "de diminuer") +
              " les prix de " +
              this.products_achat.filter((elm) => elm.selected).length +
              " produits du client " +
              this.editedItem.nom +
              " de " +
              this.taux_prix +
              " % " +
              " !! Etês-vous sûr?",
            { color: "orange darken-3" }
          )
        ) {
          let list = [];

          this.products_achat
            .filter((elm) => elm.selected)
            .forEach((element) => {
              let t = (this.taux_prix / 100) * e;
              list.push({
                id: element.id,
                prixvente: parseFloat(
                  (
                    (element.prixvente ? parseFloat(element.prixvente) : 0) *
                    (1 + t)
                  ).toFixed(2)
                ),
              });
            });
          let v = {
            list: list,
          };
          let r = await this.maj(UPDATE_PRODUCT_MULTI, v);
          if (r) {
            this.snackbar_text = "Prix maj avec succes";
            this.snackbar_color = "success";
            this.snackbar = true;
            this.products_achat.forEach((element) => {
              let t = (this.taux_prix / 100) * e;
              element.prixvente = parseFloat(
                (
                  (element.prixvente ? parseFloat(element.prixvente) : 0) *
                  (1 + t)
                ).toFixed(2)
              );
            });
            this.taux_prix = null;
            this.kps++;
          } else {
            this.snackbar_text = "Erreur";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
    },
    agence_param() {
      this.agences_headers[2].hiden = this.editedItem.country_id != 13;
      this.agences_headers[3].hiden = this.editedItem.country_id == 13;
      this.agences_headers[4].hiden = this.editedItem.country_id == 13;
      this.agences_headers[2].edit = this.editedItem.country_id == 13;
      this.agences_headers[3].edit = this.editedItem.country_id != 13;
      this.agences_headers[4].edit = this.editedItem.country_id != 13;
      this.agences_headers[6].select_list = this.countrys;
      this.agences_headers[6].show_list = this.countrys;
      this.agences_headers[9].default = this.editedItem.id;
    },
    country_change() {
      this.editedItem.form_id = null;
      if (this.editedItem.country_id == "13")
        this.editedItem.currency_id = "114";
      else this.editedItem.currency_id = null;
    },
    async OpenProductForm(item) {
      await this.$router
        .push({
          name: "product_tosell",
          params: { tier: this.item, item: item, name: "clients" },
        })
        .catch(() => {});
    },
    async product_spec_statut_change(item, item2) {
      let ok = true;
      if (item2.id == "3") {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Vous-êtes sur le point de rendre le produit " +
              item.label +
              " obsoléte !! Etês-vous sûr?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        let v = {
          product: {
            id: item.id,
            statut_id: item2.id,
          },
        };
        let r = await this.maj(UPDATE_PRODUCT, v);
        if (r) {
          item.statuts_name = item2.title;
          item.color = item2.color;
          this.products_achat.splice(item.index, 1, item);
        }
      }
    },
    async article_statut_change(item, item2) {
      let ok = true;
      if (item2.id == "3") {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Vous-êtes sur le point de rendre l'article' " +
              item.label +
              " obsoléte !! Etês-vous sûr?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        let v = {
          tierprix: {
            id: item.id,
            statut_id: item2.id,
          },
        };
        let r = await this.maj(UPDATE_PRODUCT_ARTICLE, v);
        if (r) {
          item.statuts_name = item2.title;
          item.color = item2.color;
          this.products_vente.splice(item.index, 1, item);
        }
      }
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async lib_valid() {
      this.lib = true;
      this.rc = true;
      this.nif = true;
      this.nis = true;
      this.art = true;
      this.$refs.form.resetValidation();
      if (
        this.editedItem.nom ||
        this.editedItem.RC ||
        this.editedItem.NIF ||
        this.editedItem.NIS ||
        this.editedItem.ART
      ) {
        this.libv = true;
        let w = null;
        if (this.editedItem.id > 0)
          w = {
            AND: [
              {
                OR: [
                  { column: "NOM", value: this.editedItem.nom },
                  {
                    column: "RC",
                    value: this.editedItem.RC ? this.editedItem.RC : null,
                  },
                  {
                    column: "NIF",
                    value: this.editedItem.NIF ? this.editedItem.NIF : null,
                  },
                  {
                    column: "NIS",
                    value: this.editedItem.NIS ? this.editedItem.NIS : null,
                  },
                  {
                    column: "ART",
                    value: this.editedItem.ART ? this.editedItem.ART : null,
                  },
                ],
              },

              { column: "ID", operator: "NEQ", value: this.editedItem.id },
            ],
          };
        else
          w = {
            OR: [
              { column: "NOM", value: this.editedItem.nom },
              {
                column: "RC",
                value: this.editedItem.RC ? this.editedItem.RC : null,
              },
              {
                column: "NIF",
                value: this.editedItem.NIF ? this.editedItem.NIF : null,
              },
              {
                column: "NIS",
                value: this.editedItem.NIS ? this.editedItem.NIS : null,
              },
              {
                column: "ART",
                value: this.editedItem.ART ? this.editedItem.ART : null,
              },
            ],
          };
        let v = {
          where: w,
        };
        let r = await this.requette(ALLTIERS, v);
        this.libv = false;
        if (r) {
          if (r.alltiers.length > 0) {
            if (r.alltiers.find((elm) => elm.nom == this.editedItem.nom))
              this.lib = false;
            if (
              this.editedItem.RC &&
              r.alltiers.find((elm) => elm.RC == this.editedItem.RC)
            )
              this.rc = false;
            if (
              this.editedItem.NIF &&
              r.alltiers.find((elm) => elm.NIF == this.editedItem.NIF)
            )
              this.nif = false;
            if (
              this.editedItem.NIS &&
              r.alltiers.find((elm) => elm.NIS == this.editedItem.NIS)
            )
              this.nis = false;
            if (
              this.editedItem.ART &&
              r.alltiers.find((elm) => elm.ART == this.editedItem.ART)
            )
              this.art = false;
          }
        }
        return this.$refs.form.validate();
      }
    },

    uppercase() {
      this.editedItem.RC = this.editedItem.RC.toUpperCase();
    },

    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    SelectPVForm(item) {
      this.pvdetail = item;
    },
    refresh_aux() {
      this.auxs_headers[4].whereadd = [
        { column: "ID", operator: "NEQ", value: this.editedItem.id },
        // {
        //   column: "ID",
        //   operator: "NOT_IN",
        //   value: this.tiers_auxs.map((elm) => elm.aux_id),
        // },
      ];
    },
    SelectAux(item) {
      this.aux = item;
    },
    OpenPVForm(item) {
      this.pv++;
      item.tier_id = this.editedItem.id;
      item.devise = this.editedItem.devise;
      this.pvdetail = item;
      this.isPVClosed = false;
    },
    closePVForm() {
      this.pv++;
      this.isPVClosed = true;
    },
    OpenAdressForm(item) {
      this.adress = item;
      this.isAdressClosed = false;
    },

    closeAdressForm() {
      this.adress = {};
      this.isAdressClosed = true;
    },

    resetcheck() {
      this.$refs.form.resetValidation();

      if (this.editedItem.client)
        this.editedItem.code_client = this.NewClientid;
      else this.editedItem.code_client = null;

      if (this.editedItem.fournisseur)
        this.editedItem.code_fournisseur = this.Newfournisseurid;
      else this.editedItem.code_fournisseur = null;
    },
    tab_change() {
      if (this.ch > 1) {
        this.$nextTick(() => {
          this.tab1 = this.currentTab;
        });
      } else {
        this.currentTab = this.tab1;
      }
    },
    async close() {
      let ok = true;
      if (this.ch > 1) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir quitter?"
          )
        ) {
          ok = true;
        }
      }

      if (ok) {
        if (this.route.route_name) {
          if (this.route.cmd) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                cmd: this.route.cmd,
                item_id: this.route.item_id,
              },
            });
          } else if (this.route.pfm) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                pfm: this.route.pfm,
                item_id: this.route.item_id,
              },
            });
          } else if (this.route.dmd) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                dmd: this.route.dmd,
                item_id: this.route.item_id,
              },
            });
          } else if (this.route.mvm) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                mvm: this.route.mvm,
                item_id: this.route.item_id,
              },
            });
          }
          if (this.route.facture) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                facture: this.route.facture,
                item_id: this.route.item_id,
              },
            });
          }
        } else this.$emit("close");
        this.$store.dispatch("Editing", false);
      }
    },
    hist_click() {
      this.drawer = !this.drawer;
      //this.close();
    },
    async validate() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Valider ce tier ! Etês-vous sûr?"
        )
      ) {
        this.editedItem.statut_id = "2";
        let v = {
          tier: {
            id: this.editedItem.id,
            statut_id: this.editedItem.statut_id,
          },
        };
        await this.maj(UPDATE_TIER, v);
        this.ch = 1;
        this.$emit("refresh");
        this.$store.dispatch("Changed", true);
      }
    },
    async save() {
      this.tabs = "1";
      let ok = true;
      if (this.$refs.form.validate()) {
        if (this.lib_change > 0) ok = await this.lib_valid();
        if (ok) {
          let cat;
          if (this.cat_change > 0) cat = this.editedItem.categories;
          if (this.editedItem.id > -1) {
            let v = {
              cat: cat,
              tier_id: this.editedItem.id,
              tier: [
                {
                  tier_type_id: this.editedItem.tier_type_id,
                  nom: this.editedItem.nom,
                  statut_id: this.editedItem.statut_id,
                  date_crea: this.editedItem.date_crea,
                  currency_id: this.editedItem.currency_id,
                  RC: this.editedItem.RC,
                  NIF: this.editedItem.NIF,
                  NIS: this.editedItem.NIS,
                  ART: this.editedItem.ART,
                  country_id: this.editedItem.country_id,
                  adresse: this.editedItem.adresse,
                  ville_id: this.editedItem.ville_id,
                  email: this.editedItem.email,
                  tel: this.editedItem.tel,
                  fax: this.editedItem.fax,
                  mobile: this.editedItem.mobile,
                  gps: this.editedItem.gps,
                  form_id: this.editedItem.form_id,
                  modalite_id: this.editedItem.modalite_id,
                  modepaiement: this.editedItem.modepaiement,
                  credit_acc: this.editedItem.credit_acc,
                  exe_tva: this.editedItem.exe_tva ? 1 : 0,
                  aff_exe: this.editedItem.aff_exe ? 1 : 0,
                  description: this.editedItem.description,
                  user_id: this.editedItem.user_id,

                  write_uid: this.$store.state.me.id,
                },
              ],
            };
            let r = await this.maj(TIER_UPDATE, v);
            if (r) {
              this.$emit("refresh");
              this.$store.dispatch("Changed", true);
              this.ch = 1;
              this.cat_change = 0;
            } else ok = false;
          } else {
            let pfm;
            if (this.route) if (this.route.pfm) pfm = this.route.pfm.id;
            let v = {
              cat: cat,
              tiertype: this.istier == 1 ? 2 : 1,
              pfm: pfm,
              tier: [
                {
                  tier_type_id: this.editedItem.tier_type_id,
                  nom: this.editedItem.nom,
                  statut_id: this.editedItem.statut_id,
                  date_crea: this.editedItem.date_crea,
                  RC: this.editedItem.RC,
                  NIF: this.editedItem.NIF,
                  NIS: this.editedItem.NIS,
                  ART: this.editedItem.ART,
                  adresse: this.editedItem.adresse,
                  ville_id: this.editedItem.ville_id,
                  email: this.editedItem.email,
                  tel: this.editedItem.tel,
                  fax: this.editedItem.fax,
                  mobile: this.editedItem.mobile,
                  gps: this.editedItem.gps,

                  modalite_id: this.editedItem.modalite_id,
                  modepaiement: this.editedItem.modepaiement,
                  credit_acc: this.editedItem.credit_acc,
                  exe_tva: this.editedItem.exe_tva ? 1 : 0,
                  aff_exe: this.editedItem.aff_exe ? 1 : 0,
                  form_id: this.editedItem.form_id,
                  country_id: this.editedItem.country_id,
                  description: this.editedItem.description,
                  currency_id: this.editedItem.currency_id,
                  user_id: this.editedItem.user_id,

                  create_uid: this.$store.state.me.id,
                  write_uid: this.$store.state.me.id,
                },
              ],
            };
            let r = await this.maj(INSERT_TIER, v);
            if (r) {
              this.editedItem = r.InsertTier;

              this.$nextTick(() => {
                this.ch = 1;
                this.cat_change = 0;
              });

              this.contacts_headers[0].default = this.editedItem.id;
              this.auxs_headers[0].default = this.editedItem.id;
              this.agence_param();
              this.$store.dispatch("Changed", true);
              this.$emit("refresh");
            } else {
              ok = false;
            }
          }
          this.$store.dispatch("Editing", false);
          if (this.route.route_name && ok) {
            if (this.route.cmd) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  cmd: this.route.cmd,
                  item_id: this.route.item_id,
                },
              });
            }
            if (this.route.pfm) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  pfm: this.route.pfm,
                  item_id: this.route.item_id,
                },
              });
            }
            if (this.route.dmd) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  dmd: this.route.dmd,
                  item_id: this.route.item_id,
                },
              });
            }
            if (this.route.mvm) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  mvm: this.route.mvm,
                  item_id: this.route.item_id,
                },
              });
            }
            if (this.route.facture) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  facture: this.route.facture,
                  item_id: this.route.item_id,
                },
              });
            }
          }
        }
      }
    },
  },
};
</script>
<style>
div.my-tabs [role="tab"] {
  justify-content: flex-start;
  margin-right: 2em;
}
</style>
